import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import Layout from '~/components/Common/Layout'
import { Query } from '~/types/graphql-types'
import { STYLE } from '~/utils/constant'
import { getGatsbyImageData, rem } from '~/utils/helper'

const resourcesIndexStyle = css`
  max-width: var(--width-max-content);
  margin-top: calc(var(--height-header) + 72px);
  margin-right: auto;
  margin-left: auto;
  padding-right: 34px;
  padding-left: 34px;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    margin-top: 80px;
  }

  @media screen and (${STYLE.MEDIA.PC}) {
    padding-right: 2px;
    padding-left: 2px;
  }

  & > .title {
    ${STYLE.MIXIN.CONTENT_TITLE}
  }

  & > .lead {
    ${STYLE.MIXIN.CONTENT_LEAD}
  }

  & > .contentwrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 48px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      column-gap: 24px;
      row-gap: 24px;
    }

    @media screen and (${STYLE.MEDIA.PC}) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
    }
  }

  & > .contentwrapper > .content {
    &:not(:first-of-type) {
      margin-top: 24px;
    }

    @media screen and (${STYLE.MEDIA.TABLET}) {
      &:not(:first-of-type) {
        margin-top: 0;
      }
    }
  }

  & > .contentwrapper > .content > .button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 32px;
    border-radius: 16px;
    background: var(--color-background-bright);
    text-decoration: none;
  }

  & > .contentwrapper > .content > .button > .tagwrapper > .tag {
    display: inline-block;
    padding: 3px 7px 5px;
    border: 1px solid var(--color-background-key);
    border-radius: 4px;
    color: var(--color-background-key);
    font-size: ${rem(12)};
    font-weight: bold;
    line-height: 1.33;
  }

  & > .contentwrapper > .content > .button > .contentbody > .image {
    width: 100%;
    height: auto;
    margin-top: 16px;
  }

  & > .contentwrapper > .content > .button > .contentbody > .title {
    display: -webkit-box;
    margin-top: 24px;
    overflow: hidden;
    color: var(--color-text);
    font-size: ${rem(16)};
    font-weight: bold;
    letter-spacing: -0.05%;
    line-height: 1.75;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      min-height: 56px;
    }
  }

  & > .contentwrapper > .content > .button > .contentbody > .buttonwrapper {
    margin-top: 24px;
    text-align: center;
  }

  & > .contentwrapper > .content > .button > .contentbody > .buttonwrapper > .button {
    display: inline-block;
    padding: 12px 22px;
    border-radius: 20px;
    background: var(--color-background-key);
    color: var(--color-background-bright);
    font-size: ${rem(16)};
    font-weight: bold;
    line-height: 1;
    text-decoration: none;
  }
`

const breadcrumb = [
  {
    label: '資料ダウンロード',
  },
]

const seo = {
  title: '資料ダウンロード',
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "cms-docs" } }) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
          url
          name
          id
        }
      }
    }
    allMicrocmsStgDocs {
      edges {
        node {
          title
          stgDocsId
          id
          disable_list
          image_catch {
            url
          }
          category {
            name
          }
        }
      }
    }
  }
`

const ResourcesIndexPage = ({ data }: { data: Query }) => {
  return (
    <Layout breadcrumb={breadcrumb} seo={seo}>
      <main className={resourcesIndexStyle}>
        <h1 className="title">資料ダウンロード</h1>
        <p className="lead">サービス説明資料や、お役立ち資料を無料でダウンロードいただけます。</p>
        <ul className="contentwrapper">
          {data &&
            data.allMicrocmsStgDocs.edges.map((item) => {
              const node = item.node
              if (
                !node ||
                !node.category ||
                !node.title ||
                !node.image_catch ||
                !node.image_catch.url ||
                node.disable_list
              )
                return
              const gatsbyImageData = getGatsbyImageData(data.allFile, node.image_catch.url)
              return (
                <li className="content categoryitem" key={node.id}>
                  <Link className="button" to={`/resources/${node.stgDocsId}`}>
                    <div className="tagwrapper">
                      <span className="tag">{node.category.name}</span>
                    </div>
                    <div className="contentbody">
                      <GatsbyImage className="image" image={gatsbyImageData} alt={node.title} />
                      <h2 className="title">{node.title}</h2>
                      <div className="buttonwrapper">
                        <div className="button">資料ダウンロード</div>
                      </div>
                    </div>
                  </Link>
                </li>
              )
            })}
        </ul>
      </main>
    </Layout>
  )
}

export default ResourcesIndexPage
